type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

export const pushGTMEvent = (eventName: string) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({ event: eventName })
  }
}